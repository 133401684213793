<template>
  <b-card
    no-body
  >
    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="12"
        >
          <b-row>
            <b-col
              cols="5"
              md="3"
            >
              <!-- marca -->
              <v-select
                v-model="filtros.marca"
                :options="catalogLists.Marca"
                :clearable="false"
                item-text="descripcion"
                item-value="cod"
                label="descripcion"
                deletable-chips
                chips
                multiple
                placeholder="Marca"
              />
            </b-col>

            <b-col
              cols="5"
              md="2"
            >
              <!-- generos -->
              <v-select
                v-model="filtros.genero"
                :options="catalogLists.Genero"
                multiple
                item-text="descripcion"
                item-value="cod"
                label="descripcion"
                deletable-chips
                chips
                :clearable="false"
                placeholder="Genero"
              />
            </b-col>

            <b-col
              cols="5"
              md="3"
            >
              <!-- tallas -->
              <v-select
                v-model="filtros.talla"
                :options="catalogLists.Talla"
                multiple
                item-text="descripcion"
                item-value="cod"
                label="descripcion"
                deletable-chips
                chips
                :clearable="false"
                placeholder="Talla"
              />
            </b-col>

            <b-col
              cols="5"
              md="2"
            >
              <!-- categorias -->
              <v-select
                v-model="filtros.categoria"
                :options="catalogLists.Categoria"
                multiple
                item-text="descripcion"
                item-value="cod"
                label="descripcion"
                deletable-chips
                chips
                :clearable="false"
                placeholder="Categoria"
              />
            </b-col>

            <b-col
              cols="5"
              md="2"
            >
              <b-button
                variant="primary"
                @click="getDatagrid()"
              >
                Filtrar
              </b-button>
            </b-col>
          </b-row>

        </b-col>
      </b-row>

    </div>
    <b-overlay
      :show="loader"
      rounded="sm"
      no-fade
      variant="transparent"
      opacity="1"
      blur="1px"
    >
      <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        :aria-hidden="loader ? 'true' : null"
        @filtered="onFiltered"
      >
        <template #cell(imagen)="data">
          <b-img
            :src="formatUrl(data.value)"
            class="img-thumbnail rounded-circle small-image"
            fluid
          />
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0 mx-1"
            />

          </b-col>

        </b-row>
      </div>
    </b-overlay>
  </b-card>

</template>

<script>
import {
  BCard, BTable, BImg, BRow, BCol, BPagination, BButton, BOverlay, // BFormGroup, BFormSelect, BInputGroup, BInputGroupAppend, BAvatar, BFormInput
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import catalogServices from '@core/services/catalogs/catalogService'

export default {
  components: {
    BTable,
    vSelect,
    BCard,
    BImg,
    BOverlay,
    // BAvatar,
    // BBadge,
    BRow,
    BCol,
    // BFormGroup,
    // BFormSelect,
    BPagination,
    // BInputGroup,
    // BFormInput,
    // BInputGroupAppend,
    BButton,
  },
  data() {
    return {
      loader: true,
      perPage: 10,
      pageOptions: [5, 10, 15],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      statusFilter: null,
      statusOptions: ['Downloaded', 'Draft', 'Paid', 'Partial Payment', 'Past Due'],
      searchQuery: '',
      filter: true,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'estilo', label: 'Estilo', sortable: true },
        { key: 'color', label: 'Color', sortable: true },
        { key: 'imagen', label: 'Foto', sortable: false },
        { key: 'marca', label: 'Marca', sortable: true },
        { key: 'corte', label: 'Corte', sortable: true },
        { key: 'forro', label: 'Forro', sortable: true },
        { key: 'caracteristicas', label: 'Detalles', sortable: false },
      ],
      items: [
        {
          estilo: '81715-1-A',
          // eslint-disable-next-line global-require
          color: 'PERLA',
          imagen: '',
          marca: 'KARSTEN',
          corte: 'kocrevy0@thetimes.co.uk',
          forro: 'Krasnosilka',
          caracteristicas: 'NIÑA',
        },
      ],
      catalogLists: {
        Marca: [], // most be Array of: { cod: Number, descripcion: String }
        Categoria: [], // most be Array of: { cod: Number, descripcion: String }
        Talla: [], // most be Array of: { cod: Number, descripcion: String }
        Genero: [{
          cod: 158,
          descripcion: 'NIÑA',
        },
        {
          cod: 159,
          descripcion: 'NIÑO',
        }], // most be Array of: { cod: Number, descripcion: String }
      },
      filtros: {
        marca: null, // most be Array of: { Id: Number, Name: String } 41
        categoria: null, // most be Array of: { Id: Number, Name: String } 2777
        talla: null, // most be Array of: { Id: Number, Name: String } 180
        genero: null, // most be Array of: { Id: Number, Name: String } 158
      },
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items\
    this.getDatagrid()
    this.getCatalogs()
    this.totalRows = 1
  },
  methods: {
    formatUrl(url) {
      return url.trim().replace('//', 'https://').replace(/ /g, '%20')
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async getCatalogs() {
      const listCatalogs = Object.keys(this.catalogLists)
      listCatalogs.forEach(catalog => {
        this.getSpecificCatalog(catalog)
      })
    },
    async getSpecificCatalog(catalog) {
      return catalogServices.getAllElementCatalog({
        catalogo: catalog,
      }).then(response => {
        const { data } = response
        this.catalogLists[catalog] = data
      }).catch(error => {
        this.toastErrorHandler(error)
      })
    },
    async getDatagrid() {
      this.loader = true
      const {
        marca, genero, talla, categoria,
      } = this.filtros
      catalogServices.getStylesByCatalog({
        marca: marca ? marca.map(item => item.cod).join(',') : 0,
        generos: genero ? genero.map(item => item.cod).join(',') : 0,
        tallas: talla ? talla.map(item => item.cod).join(',') : 0,
        categorias: categoria ? categoria.map(item => item.cod).join(',') : 0,
      }).then(response => {
        const { data } = response
        this.items = data
        this.totalRows = data.length
        this.loader = false
      }).catch(error => {
        this.toastErrorHandler(error)
        this.loader = false
      })
    },
  },
}
</script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }

  .small-image {
    max-width: 60px; /* Adjust the size as per your requirement */
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  </style>

  <style lang="scss">
  @import '~@core/scss/vue/libs/vue-select.scss';
  </style>
