import axiosService from '../axios/axiosService'
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig'

class CatalogService {
    // jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    getAllElementCatalog(args) {
      return axiosService.axiosIns.get(this.jwtConfig.getCatalogAll, { params: args })
    }
}

function useCatalogService() {
  const catalogService = new CatalogService()

  return {
    catalogService,
  }
}

const { catalogService } = useCatalogService()
export default catalogService
